import { Company_company_view, Offer_offer_view_category_embed } from 'generated/api';
import { Icon, IconType } from 'components/Icon';
import { useInAdminEnvironment } from 'hooks/useInAdminEnvironment';
import styles from './Tag.module.css';

export type UserTagType = 'active' | 'registration_accepted' | 'registration';
export type TagType = Offer_offer_view_category_embed.status | Company_company_view.status | UserTagType;

interface Properties {
  state: TagType;
}

const adminLabels: Record<TagType, string> = {
  [Company_company_view.status.EMPTY]: 'Leeg',
  [Offer_offer_view_category_embed.status.CONCEPT]: 'Concept',
  [Offer_offer_view_category_embed.status.CONFIRMED]: 'Ingediend bij redactie',
  [Offer_offer_view_category_embed.status.RETRACTED]: 'Offline',
  active: 'Actieve gebruiker',
  accepted: 'Fout in aagje',
  registration: 'Nieuwe gebruiker',
  registration_accepted: 'Goedgekeurd',
  submitted: 'Verstuurd naar Aagje',
};

const userLabels: Record<TagType, string> = {
  [Company_company_view.status.EMPTY]: 'Leeg',
  [Offer_offer_view_category_embed.status.CONCEPT]: 'Concept',
  [Offer_offer_view_category_embed.status.CONFIRMED]: 'Ingediend bij redactie',
  [Offer_offer_view_category_embed.status.RETRACTED]: 'Offline',
  active: 'Actief',
  accepted: 'Ingediend bij redactie',
  registration: 'Nieuw',
  registration_accepted: 'Geaccepteerd',
  submitted: 'Geaccepteerd',
};

const icons: Record<TagType, IconType | undefined> = {
  [Company_company_view.status.EMPTY]: 'eye_slash',
  [Offer_offer_view_category_embed.status.CONCEPT]: 'edit',
  [Offer_offer_view_category_embed.status.CONFIRMED]: 'spinner',
  [Offer_offer_view_category_embed.status.RETRACTED]: 'close',
  active: 'eye',
  accepted: 'check',
  registration: 'spinner',
  registration_accepted: 'spinner',
  submitted: 'spinner',
};

const greenTags = new Set<TagType>(['active']);
const orangeTags = new Set<TagType>([
  Offer_offer_view_category_embed.status.ACCEPTED,
  Offer_offer_view_category_embed.status.CONFIRMED,
  'registration',
]);
const redTags = new Set<TagType>([Offer_offer_view_category_embed.status.RETRACTED]);

export const Tag = ({ state }: Properties): JSX.Element => {
  const inAdminEnvironment = useInAdminEnvironment();

  const labelsToUse = inAdminEnvironment ? adminLabels : userLabels;
  const label = labelsToUse[state];
  const icon = icons[state];
  const className = [
    styles.tag,
    greenTags.has(state) ? styles.green : undefined,
    orangeTags.has(state) ? styles.orange : undefined,
    redTags.has(state) ? styles.red : undefined,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={className}>
      {!!icon && <Icon type={icon} />}
      {label && <div>{label}</div>}
    </div>
  );
};
