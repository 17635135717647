import { Button } from 'components/Button';
import { ReactNode, useCallback } from 'react';
import styles from './Pagination.module.css';

interface Properties {
  activePage: number;
  onPage: (page: number) => void;
  pageAmount: number;
}

export const Pagination = ({ activePage, onPage, pageAmount }: Properties): JSX.Element => {
  const pages = Array.from({ length: pageAmount })
    .fill(true)
    .map((_, index) => index + 1);
  const pagesToShow = [pages[activePage - 2], pages[activePage - 1], pages[activePage]]
    .filter(Boolean)
    .filter((page) => page !== pageAmount && page !== 1);

  const first3Pages = pages.slice(0, 3);
  const last3Pages = pages.slice(-3);

  const onNext = useCallback(() => activePage < pageAmount && onPage(activePage + 1), [activePage, onPage, pageAmount]);
  const onPrevious = useCallback(() => activePage > 1 && onPage(activePage - 1), [activePage, onPage]);

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {onPrevious && (
          <Button iconLeft="chevronLeft" secondary onClick={onPrevious}>
            Vorige
          </Button>
        )}
      </div>
      <div className={styles.pages}>
        {activePage === 1 ? (
          <FakeButton active>{pages[0]}</FakeButton>
        ) : (
          <>
            <Button secondary onClick={() => onPage(pages[0])}>
              {pages[0]}
            </Button>
            {!first3Pages.includes(activePage) && <FakeButton>...</FakeButton>}
          </>
        )}

        {pagesToShow.map((page) => {
          if (page === activePage) {
            return (
              <FakeButton key={page} active>
                {page}
              </FakeButton>
            );
          }

          return (
            <Button key={page} secondary onClick={() => onPage(page)}>
              {page}
            </Button>
          );
        })}

        {pageAmount <= 1 ? undefined : activePage === pageAmount ? (
          <FakeButton active>{pages.at(-1)}</FakeButton>
        ) : (
          <>
            {!last3Pages.includes(activePage) && <FakeButton>...</FakeButton>}
            <Button secondary onClick={() => onPage(pages.at(-1) || 1)}>
              {pages.at(-1)}
            </Button>
          </>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {onNext && (
          <Button iconLeft="chevron" secondary onClick={onNext}>
            Volgende
          </Button>
        )}
      </div>
    </div>
  );
};

const FakeButton = ({ active, children }: { active?: boolean; children: ReactNode }): JSX.Element => (
  <div className={[styles.fakeButton, active ? styles.active : undefined].filter(Boolean).join(' ')}>{children}</div>
);
