import { Button } from 'components/Button';
import { Icon, IconType } from 'components/Icon';
import { RichText } from 'components/RichText';
import styles from './EmptyState.module.css';
import { Fragment } from 'react';
import { SubText } from './Subtext';

interface Properties {
  button?: { label: string; href: string; disabled?: boolean; iconRight?: IconType; target?: string };
  icon?: IconType;
  text: string;
  title: string;
  subText?: string;
}

export const EmptyState = ({ button, icon, text, title, subText }: Properties): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.content}>
      {icon && <Icon type={icon} />}
      <div className={styles.richTextContainer}>
        <RichText>
          <h3>{title}</h3>
          <p>{text}</p>
        </RichText>
      </div>
      {button && (
        <Fragment>
          <Button target={button.target} disabled={button.disabled} href={button.href} iconRight={button.iconRight}>
            {button.label}
          </Button>
          {subText ? <SubText>{subText}</SubText> : undefined}
        </Fragment>
      )}
    </div>
  </div>
);
