import { captureException } from '@sentry/nextjs';

export function formatDateString(dateText?: string | null, options?: Intl.DateTimeFormatOptions) {
  if (!dateText) {
    return 'onbekend';
  }

  try {
    const date = new Date(dateText);
    return date.toLocaleDateString('nl-NL', options);
  } catch (error) {
    captureException(error);
  }
}
