import { CardList } from 'components/CardList';
import { CardProperties } from 'components/Card';
import { EmptyState } from 'components/EmptyState';
import { formatDateString } from 'utils/formatDateString';
import { Offer_offer_list } from 'generated/api';
import { useMemo } from 'react';

interface OfferListProperties {
  forAdmin?: boolean;
  offers?: Offer_offer_list[];
  canCreateOffer?: boolean;
}

export const OfferList = ({ offers, forAdmin, canCreateOffer }: OfferListProperties) => {
  const offersMappedToCards = useMemo(() => mapOffersToCardProperties(offers, forAdmin), [forAdmin, offers]);

  if (!offersMappedToCards || offersMappedToCards.length === 0) {
    return <OfferListEmptyState forAdmin={forAdmin} canCreateOffer={canCreateOffer} />;
  }

  return <CardList columns={['Titel', 'Startdatum', 'Einddatum', 'Status']} items={offersMappedToCards} />;
};
interface OfferListEmptyStateProperties {
  forAdmin?: boolean;
  canCreateOffer?: boolean;
}

const OfferListEmptyState = ({ forAdmin }: OfferListEmptyStateProperties) =>
  forAdmin ? (
    <EmptyState text="Probeer het op een later moment nog eens." icon="megaphone" title="Er is nog geen campagne" />
  ) : (
    <EmptyState
      text="Met een banner campagne kun je heel gericht adverteren in jouw regio. Met DPG Direct is het in een paar stappen geregeld. Nu met 100 euro starttegoed."
      icon="megaphone"
      title="Banner advertentie campagne"
      button={{
        iconRight: 'externalLink',
        label: 'Banner advertentie starten',
        href: 'https://www.dpgdirect.nl/direct/utm_source=campaign_mail&utm_medium=campaign_mail&utm_campaign=indebuurt_klantportaal&utm_content=mail_starttegoed_indebuurt',
        target: '_blank',
      }}
      subText="Naar DPG Direct"
    />
  );

function mapOffersToCardProperties(offers?: Offer_offer_list[], forAdmin?: boolean): CardProperties[] {
  return (offers || []).map(({ title, startDate, expirationDate, primaryImage, campaignTitle = '', uuid, status }) => ({
    data: [
      {
        type: 'string',
        value: title,
      },
      {
        type: 'string',
        value: formatDateString(startDate || '', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      },
      {
        type: 'string',
        value: formatDateString(expirationDate || '', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      },
    ],
    href: forAdmin ? `/admin/aanbiedingen/${uuid}` : `/aanbiedingen/${uuid}`,
    image: primaryImage,
    state: status,
    title: campaignTitle,
  }));
}
