import { ButtonProperties, Button } from 'components/Button';
import styles from './ButtonGroup.module.css';

interface Properties {
  buttons: ButtonProperties[];
}

export const ButtonGroup = ({ buttons }: Properties): JSX.Element => (
  <div className={styles.container}>
    {buttons.map((buttonProperties, index) => (
      <Button key={index} {...buttonProperties} />
    ))}
  </div>
);
