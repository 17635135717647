import styles from './CardListHeader.module.css';

interface Properties {
  columns: string[];
  onClick: (column: string) => void;
  titleLabel?: string;
}

export const CardListHeader = ({ columns, onClick, titleLabel = 'Titel' }: Properties): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.imageSpacer} />
    <div className={styles.dataContainer}>
      <button className={styles.title}>{titleLabel}</button>
      {columns?.map((value, index) => (
        <button key={index} className={styles.column} onClick={() => onClick(value)}>
          {value}
        </button>
      ))}
    </div>
    <div className={styles.fakeButtonSpacer} />
  </div>
);
