import { Card, CardProperties } from 'components/Card';
import { CardListHeader } from 'components/CardListHeader';
import styles from './CardList.module.css';

interface Properties {
  columns: string[];
  items?: CardProperties[];
  titleLabel?: string;
}

export const CardList = ({ columns, items, titleLabel }: Properties): JSX.Element => (
  <div className={styles.container}>
    <CardListHeader columns={columns} onClick={(value) => console.log(value)} titleLabel={titleLabel} />
    {items?.map(({ data, href, image, title }) => (
      <Card key={href} data={data} href={href} image={image} title={title} />
    ))}
  </div>
);
