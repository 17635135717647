import { Pagination } from 'components/Pagination';
import { VerticalMargin } from 'components/VerticalMargin';
import { Dispatch, Fragment, ReactNode, SetStateAction } from 'react';

interface PaginationContainerProperties {
  currentPage: number;
  numberOfPages: number;
  goToPage: Dispatch<SetStateAction<number>>;
  showPagination?: boolean;
  children: ReactNode;
}

export const PaginationContainer = ({
  children,
  showPagination,
  currentPage,
  numberOfPages,
  goToPage,
}: PaginationContainerProperties) => {
  if (!showPagination) {
    return <Fragment>{children}</Fragment>;
  }
  return (
    <Fragment>
      <VerticalMargin>
        <Pagination onPage={goToPage} pageAmount={numberOfPages} activePage={currentPage} />
      </VerticalMargin>
      <VerticalMargin>{children}</VerticalMargin>
      <VerticalMargin>
        <Pagination onPage={goToPage} pageAmount={numberOfPages} activePage={currentPage} />
      </VerticalMargin>
    </Fragment>
  );
};
