/* eslint-disable @next/next/no-img-element */
import { Icon } from 'components/Icon';
import { Tag, TagType } from 'components/Tag';
import Link from 'next/link';
import styles from './Card.module.css';

export interface CardProperties {
  data?: { type: 'string' | 'state'; value?: string }[];
  href: string;
  image?: string | null;
  imageAlt?: string;
  title: string | React.ReactNode;
}

export const Card = ({ data, href, image, title, imageAlt }: CardProperties): JSX.Element => (
  <Link href={href} legacyBehavior>
    <a className={styles.container}>
      <div className={styles.imageContainer}>
        {image && <img alt={imageAlt ?? (typeof title === 'string' ? title : '')} src={image} />}
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.title}>{title}</div>
        {data
          ?.filter((item) => item !== undefined)
          .map((item, index) => (
            <div key={index} className={styles.column}>
              {item.type === 'state' ? <Tag state={item.value as TagType} /> : item.value}
            </div>
          ))}
      </div>
      <div className={styles.fakeButtonContainer}>
        <div className={styles.fakeButton}>
          <Icon type="chevron" />
        </div>
      </div>
    </a>
  </Link>
);
