import { ButtonGroup } from 'components/ButtonGroup';
import { ButtonProperties } from 'components/Button';
import { RichText } from 'components/RichText';
import styles from './ListHeader.module.css';

interface Properties {
  buttons?: ButtonProperties[];
  title: string;
}

export const ListHeader = ({ buttons, title }: Properties): JSX.Element => (
  <div className={styles.container}>
    <RichText>
      <h1>{title}</h1>
    </RichText>
    {buttons && <ButtonGroup buttons={buttons} />}
  </div>
);
